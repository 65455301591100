import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ReplaySubject, Observable, Subject, BehaviorSubject} from 'rxjs';
import {SETTINGS} from '../../../core/config/commons.settings';
import {Router} from '@angular/router';

interface AuthToken {
    token: string;
}

export interface NamedRoom {
    id: string;
    name: string;
    maxParticipants?: number;
    participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable()
export class VideoChatService implements OnDestroy {
    $roomsUpdated: Observable<boolean>;

    private roomBroadcast = new ReplaySubject<boolean>();

    onErrorMessageChange: Subject<any> = new BehaviorSubject({});
    onEndTimeChange: Subject<any> = new BehaviorSubject(null);
    onUserTypeChange: Subject<any> = new BehaviorSubject('');

    constructor(private readonly http: HttpClient,
                private router: Router) {
        this.$roomsUpdated = this.roomBroadcast.asObservable();
    }

    ngOnDestroy(): void {
        if (this.roomBroadcast) {
            this.roomBroadcast.unsubscribe();
        }
    }

    private async getAuthToken(data) {
        const response: any =
            await this.http
                      .post<AuthToken>(`${SETTINGS.BASE_API}/meet/create-room-or-authenticate`, data)
                      .toPromise();
        localStorage.setItem('USER_TYPE', response.userType);
        if (!response.token) {
            this.onErrorMessageChange.next({message: response.message});
            return null;
        } else {
            this.onEndTimeChange.next(response.endTime);
            this.onUserTypeChange.next(response.userType);
            return response.token;
        }
    }

    async getAllRooms() {
        // const rooms: any = await this.http
        //            .get<Rooms>('/api/rooms')
        //            .toPromise();
        //
        // console.log(rooms);
        //
        // return rooms.activeRooms;

        return [];
    }

    async joinOrCreateRoom(name: string, identity: string, tracks: LocalTrack[]) {
        let room: Room = null;
        let token: any = null;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        try {
            token = await this.getAuthToken({identity, roomName: name, timeZone});
            if (!token) {
                await this.router.navigate(['/error']);
            } else {
                room =
                    await connect(
                        token, {
                            name,
                            tracks,
                            dominantSpeaker: true
                        } as ConnectOptions);
            }
        } catch (error) {
            console.error(`Unable to connect to Room: ${error.message}`);
        } finally {
            if (room) {
                this.roomBroadcast.next(true);
            }
        }

        return {room, token};
    }

    nudge() {
        this.roomBroadcast.next(true);
    }
}
