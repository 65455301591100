export class SETTINGS {

    public static ACCESS_TOKEN = '%&^%^*@&*#';
    public static REFRESH_TOKEN = '&@&#&$(@&##';
    public static LOGGED_IN_USER = '#$%@^^%';
    public static USER_PRIVILEGES = '%$^%((*';
    public static TEMPORARY_THERAPIST = '%$&%&&*%*';
    public static TEMPORARY_PLAN = '%$#^*^$&^*%&^*^$';

    // Local
    // public static BASE_API = 'http://localhost:3000';
    // public static SOCKET_URL = 'http://localhost:4111';
    // Dev
    public static BASE_API = 'https://api.happymind.lk';
    public static SOCKET_URL = 'https://socket.happymind.lk';

    public static STORAGE = {
      LOCATION: 'LOCATION'
    };

    public static KEYS = {
        SECRET: 'iIUsWtNZcf'
    };

    public static REGEX = {
      PASSWORD: {
        EXP: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
      },
      PHONE: {
        EXP: '^[0-9]*$'
      },
        ID: {
          EXP: '^[0-9]{9}v$'
        },
    };
}
