<!--<label for="{{ id }}" class="h5">{{ label }}</label>-->
<!--<select class="select w-full max-w-xs" id="{{ id }}" (change)="onSettingsChanged($event.target.value)">-->
<!--    <option *ngFor="let device of devices"-->
<!--            [value]="device.deviceId" [selected]="device.deviceId === selectedId">-->
<!--        {{ device.label }}-->
<!--    </option>-->
<!--</select>-->

<div class="form-control w-full max-w-xs">
    <label class="label">
        <span class="label-text">{{ label }}</span>
    </label>
    <select class="select select-bordered w-full max-w-xs" id="{{ id }}"
            (change)="onSettingsChanged($event.target.value)">
        <option *ngFor="let device of devices"
                [value]="device.deviceId" [selected]="device.deviceId === selectedId">
            {{ device.label }}
        </option>
    </select>
    <!--            <label class="label">-->
    <!--                <span class="label-text-alt">Alt label</span>-->
    <!--                <span class="label-text-alt">Alt label</span>-->
    <!--            </label>-->
</div>
