import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {SETTINGS} from '../config/commons.settings';
import {EncryptionService} from './encryption.service';
import {tap} from 'rxjs/operators';


@Injectable()
export class AuthService {

    private loginStatus = new Subject();
    private _userId = 'test';

    constructor(private http: HttpClient,
                private router: Router,
                private encryption: EncryptionService,
                 ) {
    }

    public isLoggedIn(): any {
        if (localStorage.getItem(SETTINGS.ACCESS_TOKEN) != null && localStorage.getItem(SETTINGS.REFRESH_TOKEN) != null) {
            const tokenENC: any = localStorage.getItem(SETTINGS.ACCESS_TOKEN);
            try {
                return this.encryption.decrypt(tokenENC);
            } catch (e) {
                // this.toasterService.showToaster('Invalid token', SETTINGS.TOASTER_MESSAGES.error);
                // this.logout();
            }
        }
        return false;
    }


    private setSession(authResult: any): void {
        const {access_token, refresh_token, user} = authResult;
        this.setLoggedInUser(JSON.stringify(user));
        localStorage.setItem(SETTINGS.ACCESS_TOKEN, this.encryption.encrypt(access_token));
        localStorage.setItem(SETTINGS.REFRESH_TOKEN, this.encryption.encrypt(refresh_token));
        this.loginStatus.next({isAuthenticated: true, user});
    }

    getToken(): any {
        if (localStorage.getItem(SETTINGS.ACCESS_TOKEN) != null) {
            const tokenENC: any = localStorage.getItem(SETTINGS.ACCESS_TOKEN);

            try {
                return this.encryption.decrypt(tokenENC);
            } catch (e) {

                return '';
            }

        }
        return false;
    }


    getRefreshToken(): any {
        if (localStorage.getItem(SETTINGS.REFRESH_TOKEN) != null) {
            const refreshTokenENC: any = localStorage.getItem(SETTINGS.REFRESH_TOKEN);

            try {
                return this.encryption.decrypt(refreshTokenENC);
            } catch (e) {

                return null;
            }

        }
        return null;
    }

    getLoggedInStatus(): Observable<any> {
        return this.loginStatus.asObservable();
    }

    setLoggedInUser(userStr): void {
        localStorage.setItem(SETTINGS.LOGGED_IN_USER, this.encryption.encrypt(userStr));
    }


    getLoggedInUser(): any {
        const userStrEnc: any = localStorage.getItem(SETTINGS.LOGGED_IN_USER);
        return JSON.parse(this.encryption.decrypt(userStrEnc));
    }

    refreshToken(): Observable<any> {
        return this.http.post(`${SETTINGS.BASE_API}/therapist/refresh-token`,
            {refreshToken: this.getRefreshToken()}).pipe(tap(res => {
            localStorage.setItem(SETTINGS.ACCESS_TOKEN, this.encryption.encrypt(res.access_token));
        }));
    }

    isAdmin(): any {
        return this.getLoggedInUser().userType === 'ADMIN';
    }


    get userId(): string {
        return this._userId;
    }


}
