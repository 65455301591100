import {Component, ViewChild, OnInit, OnDestroy, Pipe, PipeTransform, Renderer2} from '@angular/core';
import {
    createLocalAudioTrack,
    Room,
    LocalTrack,
    LocalVideoTrack,
    LocalAudioTrack,
    RemoteParticipant,
    LocalAudioTrackPublication
} from 'twilio-video';
import { RoomsComponent } from '../rooms/rooms.component';
import { CameraComponent } from '../camera/camera.component';
import { SettingsComponent } from '../settings/settings.component';
import { ParticipantsComponent } from '../participants/participants.component';
import { VideoChatService } from '../services/videochat.service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, timer} from 'rxjs';
import * as moment from 'moment';
import {Socket} from 'ngx-socket-io';
import {SessionStorage} from 'ngx-webstorage';

declare let mediaStream: any;

@Component({
    selector: 'app-home',
    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
    @ViewChild('rooms') rooms: RoomsComponent;
    @ViewChild('camera') camera: CameraComponent;
    @ViewChild('settings') settings: SettingsComponent;
    @ViewChild('participants') participants: ParticipantsComponent;

    activeRoom: Room;

    private notificationHub: HubConnection;

    loading = true;
    roomName: any;
    identity: any;

    countDown: Subscription;

    @SessionStorage('counter')
    counter;
    tick = 1000;

    constructor(
        private readonly videoChatService: VideoChatService,
        private route: ActivatedRoute,
        private socket: Socket,
        private readonly renderer: Renderer2,
        private router: Router) {
        this.roomName = this.route.snapshot.queryParamMap.get('roomName');
        this.identity = this.route.snapshot.queryParamMap.get('identity');
    }

    async ngOnInit() {
        // const builder =
        //     new HubConnectionBuilder()
        //         .configureLogging(LogLevel.Information)
        //         .withUrl(`${location.origin}/notificationHub`);
        //
        // this.notificationHub = builder.build();
        // this.notificationHub.on('RoomsUpdated', async updated => {
        //     if (updated) {
        //         await this.rooms.updateRooms();
        //     }
        // });
        // await this.notificationHub.start();

        // this.socket.on(this.roomName, ({status}) => {
        //     if (status === 'COMPLETED') {
        //         this.onLeaveRoom(false);
        //     }
        // });

        this.videoChatService.onEndTimeChange.subscribe((endTime: any) => {
            if (endTime) {
               setTimeout(() => {
                   this.countDown = timer(0, this.tick).subscribe(() => ++this.counter);
               }, 6000);
            }
        });

        // this.videoChatService.onEndTimeChange.subscribe((endTime: any) => {
        //     if (endTime) {
        //         this.counter = moment(moment(endTime).format()).diff(moment(), 'seconds', true);
        //         this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
        //     }
        // });
    }


    ngOnDestroy(): void {

    }

    async onSettingsChanged(deviceInfo?: MediaDeviceInfo) {
        await this.camera.initializePreview(deviceInfo.deviceId);
        if (this.settings.isPreviewing) {
            const track = await this.settings.showPreviewCamera();
            if (this.activeRoom) {
                const localParticipant = this.activeRoom.localParticipant;
                localParticipant.videoTracks.forEach(publication => publication.unpublish());
                await localParticipant.publishTrack(track);
            }
        }
    }

    async onLeaveRoom(byUser: boolean) {
        if (this.activeRoom) {
            this.activeRoom.disconnect();
            this.activeRoom = null;
        }

        const videoDevice = this.settings.hidePreviewCamera();
        // await this.camera.initializePreview(videoDevice && videoDevice.deviceId);
        this.camera.finalizePreview();
        this.participants.clear();
        localStorage.setItem('LEFT', byUser ? '1' : 'Session Ended!');
        // const url = this.router.serializeUrl(
        //     this.router.createUrlTree([`/meet/completed`],  { queryParams: { sessionId: this.roomName } })
        // );
        location.replace(`https://app.happymind.lk/meet/completed?sessionId=${this.roomName}`);
    }

    async onRoomChanged(roomName: string) {
        if (roomName) {
            if (this.activeRoom) {
                this.activeRoom.disconnect();
            }

            this.camera.finalizePreview();

            const tracks = await Promise.all([
                createLocalAudioTrack(),
                this.settings.showPreviewCamera()
            ]);

            const {room, token} =
                await this.videoChatService
                          .joinOrCreateRoom(roomName, this.identity, tracks);
            this.activeRoom = room;
            if (token) {
                setTimeout(() => {
                    this.loading = false;
                }, 3000);
            }

            this.participants.initialize(this.activeRoom.participants);
            this.registerRoomEvents();
            // this.notificationHub.send('RoomsUpdated', true);
        }
    }

    onParticipantsChanged(_: boolean) {
        this.videoChatService.nudge();
    }

    private registerRoomEvents() {
        this.activeRoom
            .on('disconnected',
                (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
            .on('participantConnected',
                (participant: RemoteParticipant) => this.participants.add(participant))
            .on('participantDisconnected',
                (participant: RemoteParticipant) => this.participants.remove(participant))
            .on('dominantSpeakerChanged',
                (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
    }

    private detachLocalTrack(track: LocalTrack) {
        if (this.isDetachable(track)) {
            track.detach().forEach(el => el.remove());
        }
    }

    private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
        return !!track
            && ((track as LocalAudioTrack).detach !== undefined
                || (track as LocalVideoTrack).detach !== undefined);
    }

    onCameraInitDone(done: boolean) {
       this.onRoomChanged(this.roomName);
    }

    async onTogglePreviewCamera(preview: boolean): Promise<any> {
        if (preview) {
            const track = await this.settings.showPreviewCamera();
            const localParticipant = this.activeRoom.localParticipant;
            localParticipant.videoTracks.forEach(publication => {
                publication.track.enable();
            });
            await localParticipant.publishTrack(track);
        } else {
            this.settings.hidePreviewCamera();
            const localParticipant: any = this.activeRoom.localParticipant;
            localParticipant.videoTracks.forEach(function (publication) {
                publication.track.stop();
                localParticipant.unpublishTrack(publication.track);
            });
        }
    }

    onToggleMuteMic(mute: boolean) {
       if (mute) {
           const localParticipant: any = this.activeRoom.localParticipant;
           localParticipant.audioTracks.forEach(function (publication) {
               publication.track.disable();
           });
       } else {
           const localParticipant: any = this.activeRoom.localParticipant;
           localParticipant.audioTracks.forEach(function (publication) {
               publication.track.enable();
           });
       }

    }
}
