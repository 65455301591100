<div class="h-screen flex justify-center items-center bg-neutral">
    <div class="card w-96 bg-base-100 shadow-xl">
        <div class="card-body text-center">
            <h1 *ngIf="!isSessionEnd" class="text-error text-xl font-bold">Something went wrong!</h1>
            <p *ngIf="!isSessionEnd">{{message}}</p>
            <h1 class="text-xl font-bold" *ngIf="isSessionEnd">{{message}}</h1>
            <hr>
            <p *ngIf="isSessionEnd && userType === 'P'">Rate your experience <a style="color: purple" href="{{rateSessionLink}}">Give Feedback</a></p>
            <p *ngIf="!isSessionEnd && userType === 'P'" class="text-sm">Contact our support team at <b><a href="mailto:info@hapymind.lk">info@happymind.lk</a></b></p>
            <p *ngIf="!isSessionEnd && userType === 'T'" class="text-sm">Contact our support team at <b><a href="mailto:support@hapymind.lk">support@happymind.lk</a></b></p>
        </div>
    </div>
</div>
