import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getToken() && !request.headers.has('Authorization')) {
      request = this.addToken(request, this.authService.getToken());
    } else {
      request = this.addGeneralHeaders(request);
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 403) {
        // this.nzMessageService.error(error.error.message);
        location.replace('https://app.happymind.lk');
      }
      if (error instanceof HttpErrorResponse && error.status === 403 && this.authService.isLoggedIn()) {
        // if (this.isRefreshing && error.status === 403) {
        //   // this.nzMessageService.error('Your session has been expired! Please login again!');
        //   localStorage.clear();
        //   // this.router.navigate(['/login']);
        //   // this.ngxUiLoaderService.stop();
        //   this.isRefreshing = false;
        //   return;
        // }
        return this.handleAccessTokenExpiredError(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  /* Headers without Token */
  private addGeneralHeaders(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        // 'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
      }
    });
  }

  /* Headers with Token */
  private addToken(request: HttpRequest<any>, token: any): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        // 'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  /* Re-send the previous request after refreshing the access token */
  private handleAccessTokenExpiredError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          return next.handle(this.addToken(request, token.access_token));
        }),
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
