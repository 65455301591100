<div class="card bg-base-100 drop-shadow-md mb-2" style="margin-top: 1.5rem">
    <div class="card-body">
       <div class="flex justify-center">
           <form class="form">
               <div *ngIf="hasAudioInputOptions">
                   <app-device-select [key]="'audioInputId'" [kind]="'audioinput'"
                                      [label]="'Microphone'" [devices]="devices"
                                      (settingsChanged)="onSettingsChanged($event)"></app-device-select>
               </div>
               <div *ngIf="hasAudioOutputOptions">
                   <app-device-select [key]="'audioOutputId'" [kind]="'audiooutput'"
                                      [label]="'Speaker'" [devices]="devices"
                                      (settingsChanged)="onSettingsChanged($event)"></app-device-select>
               </div>
               <div *ngIf="hasVideoInputOptions" style="width: 100%;!important;">
                   <app-device-select [key]="'videoInputId'" [kind]="'videoinput'" #videoSelect
                                      [label]="'Camera'" [devices]="devices"
                                      (settingsChanged)="onSettingsChanged($event)"></app-device-select>
               </div>
           </form>
       </div>
    </div>
</div>
<div class="card bg-base-100 drop-shadow-md setting-camera my-4">
    <div class="card-body text-center">
        <app-camera #camera></app-camera>
        <div class="flex flex-col items-center justify-center">
            <div class="btn-group btn-group-horizontal mb-4 px-4">
                <button type="button" class="btn w-24"
                        title="Click to toggle microphone." (click)="onTogMuteMic()">
                    <i *ngIf="!muteMic" class="fas fa-microphone"></i>
                    <i *ngIf="muteMic" class="fas fa-microphone-slash"></i>
                </button>
                <button type="button" class="btn w-24"
                        title="Click to toggle camera preview." (click)="onCameraToggle()">
                    <i *ngIf="previewCamera" class="fas fa-video"></i>
                    <i *ngIf="!previewCamera" class="fas fa-video-slash"></i>
                </button>
                <button type="button" class="btn btn-error w-24"
                        title="Click to leave this room." (click)="onLeaveRoom()">
                    Leave
                </button>
            </div>
        </div>
        <p *ngIf="showCounter && counter <= 3600 && counter > -1">Session Time : {{counter | formatTime}}</p>
    </div>
</div>
