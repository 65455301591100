<div
    [ngClass]="{ hidden: !isAlone }"
    class="lg:h-full h-96 flex flex-col items-center justify-center"
>
    <div class="text-center">
        <i class="fas fa-users text-white text-[5rem] mb-3"></i>
        <h1 class="text-white text-center uppercase">
            Waiting for participant...
        </h1>
    </div>
</div>
<div
    [ngClass]="{ hidden: isAlone }"
    class="lg:h-full h-96 flex flex-col items-center justify-center"
>
    <div #list class="w-10/12 flex justify-center"></div>
    <!--    <div class="btn-group btn-group-horizontal my-4">-->
    <!--        <button type="button" class="btn w-24"-->
    <!--                title="Click to leave this room." (click)="onTogMuteMic()">-->
    <!--            <i *ngIf="!muteMic" class="fas fa-microphone"></i>-->
    <!--            <i *ngIf="muteMic" class="fas fa-microphone-slash"></i>-->
    <!--        </button>-->
    <!--        <button type="button" class="btn w-24"-->
    <!--                title="Click to leave this room." (click)="onCameraToggle()">-->
    <!--            <i *ngIf="previewCamera" class="fas fa-video"></i>-->
    <!--            <i *ngIf="!previewCamera" class="fas fa-video-slash"></i>-->
    <!--        </button>-->
    <!--        <button type="button" class="btn btn-error w-24"-->
    <!--                title="Click to leave this room." (click)="onLeaveRoom()">-->
    <!--            Leave-->
    <!--        </button>-->
    <!--    </div>-->
</div>
