<div
    *ngIf="loading"
    class="h-screen flex justify-center items-center loading-screen bg-neutral"
>
    <img width="75" src="assets/loading.svg" alt="" />
</div>

<div class="lg:h-screen video-screen" [ngClass]="{ hidden: loading }">
    <div class="grid lg:grid-cols-12 h-full">
        <div class="lg:col-span-9 left-pane bg-black lg:overflow-hidden">
            <app-camera
                (onCameraInitCompleted)="onCameraInitDone($event)"
                #camera
                [style.display]="!!activeRoom ? 'none' : 'block'"
            ></app-camera>
            <app-participants
                class="h-full"
                #participants
                (leaveRoom)="onLeaveRoom($event)"
                (participantsChanged)="onParticipantsChanged($event)"
                (onTogglePreviewCamera)="onTogglePreviewCamera($event)"
                (onToggleMuteMic)="onToggleMuteMic($event)"
                [style.display]="!!activeRoom ? 'block' : 'none'"
                [activeRoomName]="!!activeRoom ? activeRoom.name : null"
            ></app-participants>
        </div>
        <div class="lg:col-span-3 px-4 right-pane lg:overflow-auto">
            <app-settings
                [counter]="counter"
                #settings
                (settingsChanged)="onSettingsChanged($event)"
                (onTogglePreviewCamera)="onTogglePreviewCamera($event)"
                (onToggleMuteMic)="onToggleMuteMic($event)"
                (leaveRoom)="onLeaveRoom($event)"
            ></app-settings>
            <div
                class="flex items-center justify-center"
                style="margin-top: 2rem"
            >
                <img
                    src="assets/11235596_10820.jpg"
                    style="max-width: 300px; height: auto"
                    alt=""
                />
            </div>
            <div class="flex items-center justify-center">
                <img
                    src="assets/logo.png"
                    style="max-width: 200px; height: auto"
                    alt=""
                />
            </div>
        </div>
    </div>
</div>
