import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RoomsComponent } from './rooms/rooms.component';
import { ParticipantsComponent } from './participants/participants.component';
import { CameraComponent } from './camera/camera.component';
import { SettingsComponent } from './settings/settings.component';
import { DeviceSelectComponent } from './settings/device-select.component';
import { ActivityIndicatorComponent } from './activity-indicator/activity-indicator.component';

import { VideoChatService } from './services/videochat.service';
import { DeviceService } from './services/device.service';
import { StorageService } from './services/storage.service';
import {TokenInterceptor} from '../../core/interceptors/http.interceptor';
import {EncryptionService} from '../../core/services/encryption.service';
import {AuthService} from '../../core/services/auth.service';
import {RouterModule} from '@angular/router';
import { ErrorScreenComponent } from './error-screen/error-screen.component';
import {FormatTimePipe} from '../../core/pipes/format-time.pipe';
import {SETTINGS} from '../../core/config/commons.settings';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {NgxWebstorageModule} from 'ngx-webstorage';

const config: SocketIoConfig = { url: SETTINGS.SOCKET_URL, options: {} };

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        RoomsComponent,
        ParticipantsComponent,
        CameraComponent,
        SettingsComponent,
        DeviceSelectComponent,
        ActivityIndicatorComponent,
        ErrorScreenComponent,
        FormatTimePipe
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        RouterModule.forRoot([
            {
                path: 'call',
                component: HomeComponent
            },
            {
                path: 'error',
                component: ErrorScreenComponent
            },
            {
                path: 'completed',
                component: ErrorScreenComponent
            },
        ]),
        SocketIoModule.forRoot(config),
        NgxWebstorageModule.forRoot(),
    ],
    providers: [
        DeviceService,
        VideoChatService,
        StorageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        EncryptionService,
        AuthService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
