import {Component, OnInit} from '@angular/core';
import {VideoChatService} from '../services/videochat.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-error-screen',
    templateUrl: './error-screen.component.html',
    styleUrls: ['./error-screen.component.css']
})
export class ErrorScreenComponent implements OnInit {

    message;
    isSessionEnd = false;
    sessionId: string;
    rateSessionLink: string;
    userType: string;

    constructor(private videoChatService: VideoChatService,
                private route: ActivatedRoute) {
        this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');
        this.userType = localStorage.getItem('USER_TYPE');
    }

    ngOnInit(): void {
        this.videoChatService.onErrorMessageChange.subscribe(({message}) => {
            this.rateSessionLink = `https://app.happymind.lk/rate-session/${this.sessionId}`;
            if (localStorage.getItem('LEFT') && localStorage.getItem('LEFT') === 'Session Ended!') {
                this.isSessionEnd = true;
                return this.message = 'Session Ended!';
            }
            if (localStorage.getItem('LEFT') && localStorage.getItem('LEFT') === '1') {
                this.isSessionEnd = true;
                return this.message = 'You left the call';
            }
            this.message = message;
        });
    }

}
